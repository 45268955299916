import React from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import { graphql, navigate } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import { Odin } from '../src/components/Odinv2/Odin';
import { Freya } from '../src/components/Freya/Freya';
import Ymir from '../src/components/Ymir/Ymir';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Hod from '../src/components/Hod/Hod';
import { YmirHeader } from '../src/components/Titles/YmirHeader/YmirHeaderv2';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import SEO from '../src/helpers/seo';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import tt from '../src/helpers/translation';

import './distributiva_promociones.scss';

class Promociones extends React.Component {
  state = {};

  componentDidMount() {
    if (this.props.location.hash) {
      navigate(this.props.location.pathname + this.props.location.hash);
    }
  }

  render() {
    const titleH2Data = {
      title: this.props.data.allDistPromosTitleBlock.edges[0].node.title,
    };

    const titleH3Data = {
      title: tt('PROMOCIONES VIGENTES', this.props.pageContext.locale),
    };

    const heimdallData = {
      name: this.props.data.allDistPromosTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allDistPromosTitleImageBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
      imageMobile: {
        url: this.props.data.allDistPromosTitleMobileImageBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const odinData = {
      image: this.props.data.allDistPromosEventBlock.edges[0].node.localImage.childImageSharp.fluid,
      alt: this.props.data.allDistPromosEventBlock.edges[0].node.image_alt,
      title: this.props.data.allDistPromosEventBlock.edges[0].node.event_name,
      description: this.props.data.allDistPromosEventBlock.edges[0].node.description,
      buttons: {
        cta1: this.props.data.allDistPromosEventBlock.edges[0].node.buttons.cta1,
        cta2: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
        ctaText1: this.props.data.allDistPromosEventBlock.edges[0].node.buttons.ctaText1,
        ctaText2: tt('RESERVAR', this.props.pageContext.locale),
        size: 'small',
        color1: 'white',
        color2: 'orange',
      },
    };

    const freyaData = {
      cards: this.props.data.allDistPromosPromocionesSubBlock.edges
        .map((item) => {
          return {
            image: item.node.localImage.childImageSharp.fluid,
            altImage: item.node.image_alt,
            title: item.node.title,
            imgsize: item.node.imgsize,
            twoButton: true,
            omit: item.node.omit,
            buttons: {
              cta: item.node.buttons.cta,
              size: 'small',
              color: item.node.buttons.color,
              ctaText: item.node.buttons.ctaText,
              cta2: item.node.buttons.cta2,
              size2: 'small',
              color2: 'orange',
              ctaText2: item.node.buttons.ctaText2,
            },
          };
        })
        .filter((e) => e.omit === false && e.title !== ''),
    };

    const freyaDataMobile = {
      cards: this.props.data.allDistPromosPromocionesSubBlock.edges
        .map((item) => {
          const link = item.node.buttons.cta2;
          const cta = link;
          return {
            image: item.node.localImage.childImageSharp.fluid,
            altImage: item.node.image_alt,
            title: item.node.title,
            imgsize: item.node.imgsize,
            twoButton: true,
            omit: item.node.omit,
            buttons: {
              cta: item.node.buttons.cta,
              size: 'small',
              color: item.node.buttons.color,
              ctaText: item.node.buttons.ctaText,
              cta2: cta,
              size2: 'small',
              color2: 'orange',
              ctaText2: item.node.buttons.ctaText2,
            },
          };
        })
        .filter((e) => e.omit === false && e.title !== ''),
    };

    const isMobileFreya = <Freya data={freyaDataMobile} />;
    const isDesktopFreya = <Freya data={freyaData} />;

    const ymirData = {
      title: this.props.data.allDistPromosInformativeBlock.edges[0].node.title,
      subtitle: this.props.data.allDistPromosInformativeBlock.edges[0].node.content,
      cards: [
        {
          title: tt('GRUPO ESCOLAR (4-17 AÑOS)', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('grupos/grupos-escolares', this.props.pageContext.locale),
          icon: 'grupos1',
        },
        {
          title: tt('GRUPO VACACIONAL', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/grupos/grupos-vacacionales', this.props.pageContext.locale),
          icon: 'grupos2',
        },
        {
          title: tt('FAMILIAS NUMEROSAS', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/entradas/familia-numerosa', this.props.pageContext.locale),
          icon: 'grupos3',
        },
      ],
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allDistPromosSeoData.edges[0].node._0.title}
          description={this.props.data.allDistPromosSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allDistPromosRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allDistPromosTitleImageBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="promotions-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allDistPromosBreadCrumbBlock.edges[0].node.name}
              />
              <H2 data={titleH2Data} />
              <Odin data={odinData} locale={this.props.pageContext.locale} />
              <H3 data={titleH3Data} />
              <MediaServerRender predicted="mobile" hydrated>
                <MediaMatcher
                  mobile={isMobileFreya}
                  tablet={isMobileFreya}
                  desktop={isDesktopFreya}
                />
              </MediaServerRender>
              <a id="group" />
              <div className="promotions-content-promo">
                <YmirHeader
                  title={ymirData.title}
                  subtitle={ymirData.subtitle}
                  subtitle2={ymirData.subtitle2}
                />
                <Ymir data={ymirData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
Promociones.propTypes = {};
/* eslint-enable react/require-default-props */

export default Promociones;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query allDistPromociones($locale: String!) {
    allDistPromosSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allDistPromosTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }

    allDistPromosTitleImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allDistPromosTitleMobileImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allDistPromosEventBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          description
          event_name
          title
          buttons {
            cta1
            ctaText1
          }
        }
      }
    }

    allDistPromosPromocionesSubBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          id
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          omit
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
            ctaText2
            cta2
          }
          description
        }
      }
    }
    allDistPromosInformativeBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          content
        }
      }
    }
    allDistPromosBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allDistPromosRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;
